export default class SectorsFilter {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.grantToken = "";
    this.grantTypeToken = "";
    this.textSearch = "";
  }
  fillData(data) {
    this.grantToken = data.grantToken;
    this.grantTypeToken = data.grantTypeToken;
    this.textSearch = data.textSearch;
  }
}
