import { PAGE_SIZE } from "@/utils/constants";
import axios from "axios";
import PaginationData from "./../../../general/PaginationData";
import GrantsFilter from "./GrantsFilter";

export default class Grant {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.grantToken = "";
    this.grantNameCurrent = "";
    this.grantNameEn = "";
    this.grantNameAr = "";
    this.grantNameUnd = "";
    this.grantTypeToken = "";
    this.grantTypeNameCurrent = "";
    this.grantTypeNameEn = "";
    this.grantTypeNameAr = "";
    this.grantTypeNameUnd = "";
    this.grantDescriptionCurrent = "";
    this.grantDescriptionEn = "";
    this.grantDescriptionAr = "";
    this.grantDescriptionUnd = "";
    this.grantNotes = "";
    this.grantArchiveStatus = "";
  }
  fillData(data) {
    this.grantToken = data.grantToken;
    this.grantNameCurrent = data.grantNameCurrent;
    this.grantNameEn = data.grantNameEn;
    this.grantNameAr = data.grantNameAr;
    this.grantNameUnd = data.grantNameUnd;
    this.grantTypeToken = data.grantTypeToken;
    this.grantTypeNameCurrent = data.grantTypeNameCurrent;
    this.grantTypeNameEn = data.grantTypeNameEn;
    this.grantTypeNameAr = data.grantTypeNameAr;
    this.grantTypeNameUnd = data.grantTypeNameUnd;
    this.grantDescriptionCurrent = data.grantDescriptionCurrent;
    this.grantDescriptionEn = data.grantDescriptionEn;
    this.grantDescriptionAr = data.grantDescriptionAr;
    this.grantDescriptionUnd = data.grantDescriptionUnd;
    this.grantNotes = data.grantNotes;
    this.grantArchiveStatus = data.grantArchiveStatus;
  }

  async getAllGrants(
    language,
    userAuthorizeToken,
    pagination = new PaginationData(),
    filterData = new GrantsFilter()
  ) {
    try {
      return await axios.get(
        `/Grants/GetAllGrants/?language=${language}&userAuthorizeToken=${userAuthorizeToken}&cityArchiveStatus=false&page=${pagination.selfPage}&paginationStatus=true&pageSize=${PAGE_SIZE}&filterStatus=true&grantTypeToken=${filterData.grantTypeToken}&textSearch=${filterData.textSearch}`
      );
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async getDialogOfGrants(
    language,
    userAuthorizeToken,
    filterData = new GrantsFilter()
  ) {
    return await axios.get(
      `/Grants/GetDialogOfGrants?language=${language}&userAuthorizeToken=${userAuthorizeToken}&filterStatus=true&grantTypeToken=${filterData.grantTypeToken}&textSearch=${filterData.textSearch}`
    );
  }

  async getGrantDetails(language, userAuthorizeToken) {
    try {
      return await axios.get(
        `/Grants/GetGrantDetails?language=${language}&userAuthorizeToken=${userAuthorizeToken}&grantToken=${this.grantToken}`
      );
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async addOrUpdateGrant(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      grantToken: this.grantToken,
      grantNameEn: this.grantNameEn,
      grantNameAr: this.grantNameAr,
      grantNameUnd: this.grantNameUnd,
      grantTypeToken: this.grantTypeToken,
      grantDescriptionEn: this.grantDescriptionEn,
      grantDescriptionAr: this.grantDescriptionAr,
      grantDescriptionUnd: this.grantDescriptionUnd,
      grantNotes: this.grantNotes,
    };

    try {
      if (this.grantToken == "" || this.grantToken == undefined) {
        return await axios.post(`/Grants/AddGrant`, data);
      } else {
        return await axios.post(`/Grants/UpdateGrant`, data);
      }
    } catch (e) {
      return this.$t("errorCatch");
    }
  }

  async archiveGrant(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      grantToken: this.grantToken,
    };

    try {
      return await axios.post(`/Grants/ArchiveGrant`, data);
    } catch (e) {
      return this.$t("errorCatch");
    }
  }
}
