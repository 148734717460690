<template>
  <div class="">
    <PreLoader v-if="isLoading" />

    <GrantForm
      v-if="!isLoading"
      :grant="grant"
      v-on:addOrUpdateGrant="addOrUpdateGrant()"
      :submitName="$t('add')"
    />
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import { STATUS } from "@/utils/constants";
import GrantForm from "@/components/settings/settingsOther/grants/GrantForm.vue";
import Grant from "@/models/settings/settingsOther/grants/Grant";
import createToastMixin from "@/utils/create-toast-mixin";

export default {
  mixins: [createToastMixin],
  components: {
    PreLoader,
    GrantForm,
  },
  data() {
    return {
      isLoading: false,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      grant: new Grant(),
    };
  },
  methods: {
    async addOrUpdateGrant() {
      this.isLoading = true;
      try {
        let response = await this.grant.addOrUpdateGrant(
          this.language,
          this.userAuthorizeToken
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.showMsg(response.data.msg, true);
          this.grant.setInitialValue();
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.showMsg(response.data.msg);
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (e) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
  },
  async created() {},
};
</script>
